import _util from "util";
import _ansiColors from "ansi-colors";
import _extendShallow from "extend-shallow";
import _arrDiff from "arr-diff";
import _arrUnion from "arr-union";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var util = _util;
var colors = _ansiColors;
var extend = _extendShallow;
var differ = _arrDiff;
var union = _arrUnion;
var nonEnum = ["message", "name", "stack"];
var ignored = union(nonEnum, ["__safety", "_stack", "plugin", "showProperties", "showStack"]);
var props = ["fileName", "lineNumber", "message", "name", "plugin", "showProperties", "showStack", "stack"];

function PluginError(plugin, message, options) {
  if (!((this || _global) instanceof PluginError)) {
    throw new Error("Call PluginError using new");
  }

  Error.call(this || _global);
  var opts = setDefaults(plugin, message, options);
  var self = this || _global; // If opts has an error, get details from it

  if (typeof opts.error === "object") {
    var keys = union(Object.keys(opts.error), nonEnum); // These properties are not enumerable, so we have to add them explicitly.

    keys.forEach(function (prop) {
      self[prop] = opts.error[prop];
    });
  } // Opts object can override


  props.forEach(function (prop) {
    if (prop in opts) {
      (this || _global)[prop] = opts[prop];
    }
  }, this || _global); // Defaults

  if (!(this || _global).name) {
    (this || _global).name = "Error";
  }

  if (!(this || _global).stack) {
    /**
     * `Error.captureStackTrace` appends a stack property which
     * relies on the toString method of the object it is applied to.
     *
     * Since we are using our own toString method which controls when
     * to display the stack trace, if we don't go through this safety
     * object we'll get stack overflow problems.
     */
    var safety = {};

    safety.toString = function () {
      return this._messageWithDetails() + "\nStack:";
    }.bind(this || _global);

    Error.captureStackTrace(safety, arguments.callee || (this || _global).constructor);
    (this || _global).__safety = safety;
  }

  if (!(this || _global).plugin) {
    throw new Error("Missing plugin name");
  }

  if (!(this || _global).message) {
    throw new Error("Missing error message");
  }
}

util.inherits(PluginError, Error);
/**
 * Output a formatted message with details
 */

PluginError.prototype._messageWithDetails = function () {
  var msg = "Message:\n    " + (this || _global).message;

  var details = this._messageDetails();

  if (details !== "") {
    msg += "\n" + details;
  }

  return msg;
};
/**
 * Output actual message details
 */


PluginError.prototype._messageDetails = function () {
  if (!(this || _global).showProperties) {
    return "";
  }

  var props = differ(Object.keys(this || _global), ignored);
  var len = props.length;

  if (len === 0) {
    return "";
  }

  var res = "";
  var i = 0;

  while (len--) {
    var prop = props[i++];
    res += "    ";
    res += prop + ": " + (this || _global)[prop];
    res += "\n";
  }

  return "Details:\n" + res;
};
/**
 * Override the `toString` method
 */


PluginError.prototype.toString = function () {
  var detailsWithStack = function (stack) {
    return this._messageWithDetails() + "\nStack:\n" + stack;
  }.bind(this || _global);

  var msg = "";

  if ((this || _global).showStack) {
    // If there is no wrapped error, use the stack captured in the PluginError ctor
    if ((this || _global).__safety) {
      msg = (this || _global).__safety.stack;
    } else if ((this || _global)._stack) {
      msg = detailsWithStack((this || _global)._stack);
    } else {
      // Stack from wrapped error
      msg = detailsWithStack((this || _global).stack);
    }

    return message(msg, this || _global);
  }

  msg = this._messageWithDetails();
  return message(msg, this || _global);
}; // Format the output message


function message(msg, thisArg) {
  var sig = colors.red(thisArg.name);
  sig += " in plugin ";
  sig += "\"" + colors.cyan(thisArg.plugin) + "\"";
  sig += "\n";
  sig += msg;
  return sig;
}
/**
 * Set default options based on arguments.
 */


function setDefaults(plugin, message, opts) {
  if (typeof plugin === "object") {
    return defaults(plugin);
  }

  opts = opts || {};

  if (message instanceof Error) {
    opts.error = message;
  } else if (typeof message === "object") {
    opts = message;
  } else {
    opts.message = message;
  }

  opts.plugin = plugin;
  return defaults(opts);
}
/**
 * Extend default options with:
 *
 *  - `showStack`: default=false
 *  - `showProperties`: default=true
 *
 * @param  {Object} `opts` Options to extend
 * @return {Object}
 */


function defaults(opts) {
  return extend({
    showStack: false,
    showProperties: true
  }, opts);
}
/**
 * Expose `PluginError`
 */


exports = PluginError;
export default exports;